import React, { useContext } from "react";
import { Link, Route, Routes } from "react-router-dom";
import { Switch } from "antd";
import { RocketOutlined } from "@ant-design/icons";
import Search from "./Search";
import DataDisplay from "./DataDisplay";
import ProfileDropdown from "./ProfileDropdown";
import { AppContext } from "./AppContext";

export default function Header({ user }) {
  const { showAirborne, setShowAirborne } = useContext(AppContext);
  
  return (
    <header className="ctot-navbar-dashboard">
      <Link to="/#top" className="ctot-navbar-dashboard-logo" aria-label="Home">
        <img
          src="/images/logo/vector/default-monochrome-white-nosub.svg"
          alt="CTOT APP"
          width="auto"
          
        />
      </Link>

      <nav className="ctot-navbar-dashboard-items">
        <Search 
          className="ctot-navbar-dashboard-item ctot-navbar-dashboard-item-search" 
          aria-label="Search"
        />
        <Routes>
          <Route
            path="/"
            element={
              <DataDisplay 
                className="ctot-navbar-dashboard-item ctot-navbar-dashboard-item-display" 
                aria-label="Data Display"
              />
            }
          />
        </Routes>

        <ProfileDropdown
          user={user}
          className="ctot-navbar-dashboard-item ctot-navbar-dashboard-item-profile"
          aria-label="User Profile"
        />
      </nav>
    </header>
  );
}
