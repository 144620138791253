import * as React from "react";

import { AppContext } from "./AppContext";

import { Table } from "antd";

import _ from "lodash";
import FlightDetails from "./FlightDetails";

import { currentFlights } from "../graphql/reactiveVars.js";
import { useReactiveVar } from "@apollo/client";

export default function FlightsTable() {
  let { setTitle, currentColumns } = React.useContext(AppContext);

  let allFlights = useReactiveVar(currentFlights);

  const [showModal, setShowModal] = React.useState(false);
  const [currentFlightId, setCurrentFlightId] = React.useState(false);

  const ctotCount = _.sumBy(allFlights, (ele) =>
    _.isString(_.get(ele, "flight.calculatedTakeOffTime"))
  );

  allFlights = allFlights.map((ele, i) => ({ key: i, ...ele }));

  allFlights = _.filter(
    allFlights,
    (ele) => _.get(ele, "flight._status.code") !== "RESTRICTED"
  );

  React.useEffect(() => {
    setTitle(`CTOT Web [${ctotCount} active CTOT]`);
  }, [ctotCount]);

  const elementRef = React.useRef(null);
  const [heightToBottom, setHeightToBottom] = React.useState(500);

  React.useEffect(() => {
    const calculateHeightToBottom = () => {
      if (elementRef.current) {
        const rect = elementRef.current.getBoundingClientRect();
        const height = window.innerHeight - rect.top;
        setHeightToBottom(height-60);
      }
    };

    // Calculate height initially
    calculateHeightToBottom();

    // Recalculate height on window resize
    window.addEventListener("resize", calculateHeightToBottom);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("resize", calculateHeightToBottom);
    };
  }, []);

  return (
    <div ref={elementRef}>
      <Table
        dataSource={allFlights}
        columns={currentColumns}
        pagination={false}
        className={"dashboard-flight-table"}
        scroll={{ x: 500, y: heightToBottom }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              console.log(record);
              setCurrentFlightId(record.uniqId);
              setShowModal(!showModal);
            }, // click row
          };
        }}
      />
      <FlightDetails
        showModal={showModal}
        setShowModal={setShowModal}
        uniqId={currentFlightId}
      />
    </div>
  );
}
