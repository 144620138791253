import _ from "lodash";
import * as React from "react";
import Map, { Source, Layer, Popup } from "react-map-gl/dist/es5";
import "mapbox-gl/dist/mapbox-gl.css";
import { mapStyle } from "../styles/mapStyle.js";

function polygonCenter(polygon) {
  if ("geometry" in polygon) {
    polygon = polygon.geometry;
  }

  if (polygon.type === "MultiPolygon") {
    polygon = polygon.coordinates[0][0];
  }

  if (!Array.isArray(polygon)) polygon = polygon.coordinates[0];

  var miny = 1000;
  var maxy = -1000;
  var minx = 1000;
  var maxx = -1000;

  for (var i = 0; i < polygon.length; i++) {
    var point = polygon[i];
    var x = point[0];
    var y = point[1];

    if (x < minx) minx = x;
    else if (x > maxx) maxx = x;
    if (y < miny) miny = y;
    else if (y > maxy) maxy = y;
  }

  return {
    type: "Point",
    coordinates: [minx + (maxx - minx) / 2, miny + (maxy - miny) / 2],
  };
}

export default function RestrictionMap(props) {
  const data = props;

  const [hoverInfo, setHoverInfo] = React.useState(null);

  const onHover = React.useCallback((event) => {
    const feature = event.features && event.features[0];

    if (feature) {
      console.log("hover feature", feature?.properties?.locationId);
      setHoverInfo({
        longitude: event.lngLat.lng,
        latitude: event.lngLat.lat,
        properties: {
          name: feature?.properties?.locationId,
          description: feature?.properties?.locationId,
        },
      });
    } else {
      setHoverInfo(null);
    }
  }, []);

  if (_.isEmpty(data.flight)) return null;

  const tto = _.get(data, "flight.targetTimeOverFix");
  const ttoPointId = _.get(tto, "point.pointId");

  let regulationsGeoJsonFeatures = _.chain(data)
    .get("flight.regulations", [])
    .map("geoJson")
    .filter((ele) => !_.isEmpty(ele))
    .filter((ele) => _.get(ele, "geometry.type") === "MultiPolygon")
    .map((ele) => {
      return {
        ...ele,
        properties: {
          ...ele.properties,
          center: polygonCenter(ele),
        },
      };
    })
    .value();

  let regulationsFirGeoJsonFeatures = _.chain(data)
    .get("flight.regulations", [])
    .map("firGeoJson")
    .filter((ele) => !_.isEmpty(ele))
    //.map((ele) => _.set(ele, "geometry.type", "Polygon"))
    .map((ele) => {
      return {
        ...ele,
        properties: {
          ...ele.properties,
          center: polygonCenter(ele),
        },
      };
    })
    .value();

  let regulationGeoJson = {
    type: "FeatureCollection",
    features: regulationsGeoJsonFeatures,
  };

  let regulationFirGeoJson = {
    type: "FeatureCollection",
    features: regulationsFirGeoJsonFeatures,
  };

  let flightLine = _.get(data, "flight.route", []);

  flightLine = _.filter(
    flightLine,
    (ele, i) => ele?.coords && ele.designator.length > 1
  );

  flightLine = _.map(flightLine, (ele) => ({
    designator: ele.designator,
    latitude: _.get(ele, "coords.1"),
    longitude: _.get(ele, "coords.0"),
  }));

  flightLine = _.map(flightLine, (ele) => {
    if (ele.designator === ttoPointId) ele.ttoPoint = true;
    return ele;
  });

  const flightLineGeoJson = {
    type: "Feature",
    properties: {},
    geometry: {
      type: "LineString",
      coordinates: flightLine.map((ele) => [ele.longitude, ele.latitude]),
    },
  };

  const routePointsGeoJson = {
    type: "FeatureCollection",
    features: flightLine.map((ele) => ({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [ele.longitude, ele.latitude],
      },
      properties: {
        title: "Location Id",
        description: ele.designator,
      },
    })),
  };

  let initBounds = [
    [-10, 40],
    [10, 50],
  ];

  const route = _.get(data, "flight.route", []);

  if (route.length > 1)
    initBounds = [_.first(route)?.coords, _.last(route)?.coords];

  return (
    <Map
      bounds={initBounds}
      fitBoundsOptions={{
        padding: 20, // padding to keep the bounds away from the edge of the map
      }}
      style={{ width: "100%", height: 400 }}
      mapStyle={mapStyle}
      //mapStyle="mapbox://styles/ctotapp/cm6vejveh017z01qrg54q1j2r"
      //mapStyle="mapbox://styles/mapbox/dark-v11"
      //mapStyle="mapbox://styles/ctotapp/cm6v2yc0d01cc01s289ji7mrn"
      //mapboxAccessToken="pk.eyJ1Ijoic2VyZ2VqYWNvZCIsImEiOiJjbDMzYmQ4NXkwNDlqM2pxOXI3b3U5ZzZwIn0.iGjJZbAgwx79alPDBgCP-A"
      //mapStyle="mapbox://styles/ctotapp/cm6vejveh017z01qrg54q1j2r"
      mapboxAccessToken="pk.eyJ1IjoiY3RvdGFwcCIsImEiOiJjbHBtcDBpd3EwYXp4Mm10MzJhejB0OTgwIn0.GOfbY1azLCVobS8iIiyj7w"
      cooperativeGestures={true}
      attributionControl={false}
      interactiveLayerIds={["regulationFir"]} // Needed for hover interaction
      //onMouseMove={onHover}
      //onMouseLeave={() => setHoverInfo(null)} // Remove popup on leave
    >
      <Source type="geojson" data={regulationFirGeoJson}>
        <Layer
          id={"regulationFir"}
          type="fill"
          paint={{
            "fill-color": "rgba(255,120, 120, .2)",
            "fill-outline-color": "rgba(255,120, 120, .4)",
          }}
        />
      </Source>

      <Source type="geojson" data={regulationGeoJson}>
        <Layer
          id={"regulation"}
          type="fill"
          paint={{
            "fill-color": "rgba(255,120, 120, .5)",
            "fill-outline-color": "rgba(255,120, 120, .7)",
          }}
        />
      </Source>

      <Source type="geojson" data={routePointsGeoJson}>
        <Layer
          id="routePoints"
          type="circle"
          paint={{
            "circle-radius": 3,
            "circle-color": "#4dabf7",
            "circle-stroke-width": 2,
            "circle-stroke-color": "white",
            "circle-blur": 0.1,
            "circle-opacity": 0.9,
          }}
        />
        <Layer
          id="routePoints-labels"
          type="symbol"
          layout={{
            "text-field": ["get", "description"],
            "text-size": 8,
            "text-offset": [-0.5, -0.5],
            "text-anchor": "right",
          }}
          paint={{
            "text-color": "#4dabf7",
            "text-halo-width": 4,
            "text-halo-color": "white",
          }}
        />
      </Source>

      <Source type="geojson" data={flightLineGeoJson} lineMetrics={true}>
        <Layer
          id={"flightLine"}
          type="line"
          layout={{
            "line-join": "round",
            "line-cap": "round",
          }}
          paint={{
            "line-color": "rgba(77, 171, 247, 0.8)",
            "line-width": 2,
            "line-dasharray": [2, 2],
          }}
        />
        <Layer
          id="flightline-labels"
          type="symbol"
          layout={{
            "text-field": ["get", "description"],
            "text-size": 12,
            "text-offset": [0, -1.5],
            "text-anchor": "bottom",
            "text-allow-overlap": true,
          }}
          paint={{
            "text-color": "#4547b5",
            "text-halo-width": 2,
            "text-halo-color": "white",
          }}
        />

       {/*  {hoverInfo && (
          <Popup
            longitude={hoverInfo.longitude}
            latitude={hoverInfo.latitude}
            closeButton={false} // No close button since it's hover-based
            closeOnClick={false}
            style={{ backgroundColor: "rgba(255,120, 120, .4)" }}
          >
            <div>
              <h4 style={{ color: "red" }}>{hoverInfo.properties.name}</h4>
              <p>{hoverInfo.properties.description}</p>
            </div>
          </Popup>
        )} */}
      </Source>
    </Map>
  );
}
