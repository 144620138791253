import React from "react";
import { Button, Descriptions, Divider, Drawer, Space, Table } from "antd";
import { useQuery } from "@apollo/client";
import { flightGQL } from "../graphql/queries";
import _ from "lodash";
import moment from "moment";
import delayCodes from "../api/delayCodes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RestrictionMap from "./RestrictionMap";
import FlightTimeline from "./FlightTimeline";
import {
  faClose,
  faExternalLinkAlt,
  faSync,
} from "@fortawesome/free-solid-svg-icons";
import ErrorMessage from "./ErrorMessage";

const FlightDetails = ({ showModal, setShowModal, uniqId }) => {
  const handleCancel = () => {
    setShowModal(false);
  };

  const [lastUpdate, setLastUpdate] = React.useState(moment.utc());

  const setUpdateTime = () => {
    console.log("updated");
    setLastUpdate(moment.utc());
  };

  // Prepare the query
  let { loading, error, data, refetch } = useQuery(flightGQL, {
    variables: { uniqId },
    fetchPolicy: "network-only",
    skip: !uniqId,
  });

  // Retrieve the flight from the query result
  let flight = _.get(data, "flight", {});

  if (flight._errorCode) error = { _errorCode: flight._errorCode };

  const {
    callsign,
    depIcao,
    arrIcao,
    tsat,
    ctot,
    tobt,
    eobt,
    depAirportType,
    mostPenalisingRegulation,
    mostPenalisingRegulationCause,
    altIcaos= [],
    flightState,
    suspensionStatus,
    taxiDuration,
    regulations = [],
    registration,
    icaoRoute,
    targetTimeOverFix,
  } = flight;

  const iataDelayCode = _.get(mostPenalisingRegulationCause, "iataDelayCode");

  const depProcId = _.get(flight, "depProc.id");

  const skyVectorLink = `https://skyvector.com/?ll=52.1808,-9.52389&chart=302&fpl=${depIcao} ${icaoRoute} ${arrIcao}`;

  const numberOfRestrictions = (regulations && regulations?.length) || 0;

  const regulationsElements = _.map(regulations, (ele, i) => {
    return (
      <p key={i}>
        Restriction {i + 1}/{numberOfRestrictions}: {ele.type}/{ele.id}
      </p>
    );
  });

  const allFlightData = [
    {
      title: "Registration",
      value: registration,
    },
    {
      title: "FlightRadar24",
      value: registration && (
        <a
          href={`https://www.flightradar24.com/data/aircraft/${registration}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Find Plane
          <FontAwesomeIcon
            icon={faExternalLinkAlt}
            style={{ fontSize: 14, marginLeft: 6 }}
          />
        </a>
      ),
    },
    {
      title: "SkyVector",
      value: registration && (
        <a href={skyVectorLink} target="_blank" rel="noopener noreferrer">
          View Route
          <FontAwesomeIcon
            icon={faExternalLinkAlt}
            style={{ fontSize: 14, marginLeft: 6 }}
          />
        </a>
      ),
    },
    {
      title: "EOBT",
      value: moment.utc(eobt).format("HH:mm"),
    },
    {
      title: "TOBT",
      value: tobt ? moment.utc(tobt).format("HH:mm") : "-",
    },
    {
      title: "TSAT",
      value: tsat ? moment.utc(tsat).format("HH:mm") : "-",
    },
    {
      title: "CTOT",
      value: (
        <span style={{ color: "red", fontWeight: 500 }}>
          {moment.utc(ctot).format("HH:mm")}
        </span>
      ),
      display: !!ctot,
    },
    {
      title: "Airport Type",
      value: depAirportType,
    },
    {
      title: "SID",
      value: depProcId,
    },
    {
      title: "IATA Delay Code",
      value: _.get(mostPenalisingRegulationCause, "iataDelayCode"),
      display: !!ctot,
    },
    {
      title: "IATA Delay Code Full",
      value: (_.find(delayCodes, { code: iataDelayCode }) || {}).details,
      display: !!ctot,
    },
    {
      title: "Ready to Depart",
      value: _.get(flight, "readyStatus.readyToDepart") ? "Yes" : "No",
    },
    {
      title: "Ready for Improvement",
      value: _.get(flight, "readyStatus.readyForImprovement") ? "Yes" : "No",
    },

    {
      title: "Suspension Status",
      value: suspensionStatus,
    },
    {
      title: "Flight State",
      value: flightState,
    },
    {
      title: "Taxi Time",
      value: moment.duration(taxiDuration).asMinutes(),
    },
    {
      title: "Most Penalising Regulation",
      value: mostPenalisingRegulation,
      display: !!ctot,
    },
    {
      title: "Most Penalising Regulation Cause",
      value: _.get(mostPenalisingRegulationCause, "iataDelayCode"),
      display: !!ctot,
    },
    {
      title: "Regulation Locations",
      value: regulationsElements,
      display: !!ctot,
    },
    {
      title: "Departure Airport",
      value: depIcao,
    },
    {
      title: "Route",
      value: icaoRoute,
    },
    {
      title: "Arrival Airport",
      value: arrIcao,
    },
    {
      title: "Alternate Airport(s)",
      value: altIcaos.join(" "),
    },
  ];

  const RegulationsColumns = [
    {
      title: "Id",
      dataIndex: "id",
    },
    {
      title: "Location",
      render: (text, record) => record?.locationId,
    },
    {
      title: "Type",
      render: (text, record) => record?.type,
    },
  ];

  const ttoDescriptionItems = [
    {
      key: "1",
      label: "TTO Time",
      children: _.get(targetTimeOverFix, "targetTime"),
    },
    {
      key: "2",
      label: "TTO Point",
      children: _.get(targetTimeOverFix, "point.pointId"),
    },
    {
      key: "3",
      label: " Target Level",
      children:
        _.get(targetTimeOverFix, "targetLevel.unit") +
        _.get(targetTimeOverFix, "targetLevel.level"),
    },
  ];

  console.log(targetTimeOverFix);

  const Regulations = () => {
    if (regulations?.length > 0)
      return (
        <>
          <h3>Regulations</h3>
          <Table
            dataSource={regulations}
            columns={RegulationsColumns}
            pagination={false}
          />
          <br />
          <h3>Target Time Over Fix (TTO)</h3>
          <Descriptions
            size="small"
            bordered
            column={1}
            items={ttoDescriptionItems}
            labelStyle={{ width: "25ch" }}
          />
          <br />
        </>
      );
    else
      return (
        <>
          <h2>Regulations</h2>
          <p>None</p>
        </>
      );
  };

  return (
    <Drawer
      title={
        <div className="drawer-title">
          <span className="airport-code">{callsign || ""}</span>{" "}
          <Divider type="vertical" className="divider" /> {depIcao} - {arrIcao}{" "}
          <Divider type="vertical" className="divider" />
          <span className="last-update">
            Last update {lastUpdate.format("HH:mm (z)")}
          </span>
        </div>
      }
      size="large"
      placement="right"
      onClose={handleCancel}
      open={showModal}
      styles={{
        wrapper: {
          width: "80%",
          //minWidth: "800px",}
        },
      }}
      className="drawer"
      destroyOnClose={true}
      extra={
        <Space>
          <Button
            //type="primary"
            onClick={() => {
              refetch();
              setUpdateTime();
            }}
            loading={loading}
            style={{ marginLeft: "10px" }}
            icon={<FontAwesomeIcon icon={faSync} />}
          >
            Refresh Flight Data
          </Button>
          <Button
            type="primary"
            onClick={handleCancel}
            icon={<FontAwesomeIcon icon={faClose} />}
          >
            Close
          </Button>
        </Space>
      }
    >
      <ErrorMessage error={error} />
      <FlightTimeline flight={flight} />
      <Divider />
      <RestrictionMap
        regulations={regulations}
        departureAirport={depIcao}
        arrivalAirport={arrIcao}
        icaoRoute={icaoRoute}
        flight={flight}
      />
      <br />
      <Regulations />
      <br />
      <h2>Flight Data</h2>

      <Descriptions
        size="small"
        bordered
        column={1}
        items={_.chain(allFlightData)
          .filter((ele) => ele.display !== false)
          .map((ele, i) => ({
            key: i,
            label: ele.title,
            children: ele.value,
          }))
          .value()}
        labelStyle={{ width: "25ch" }}
      />
    </Drawer>
  );
};

export default FlightDetails;
