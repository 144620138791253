import React, { useEffect, useCallback, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import _ from "lodash";
import apolloClient from "../graphql/client";
import constants from "../../constants";

export default function useLoginLink() {
  console.log("Loading useLoginLink");

  let [searchParams] = useSearchParams();
  let navigate = useNavigate();

  let token = searchParams.get("token");

  // To prevent multiple logins for the same token
  const hasLoggedIn = useRef(false);

  const login = useCallback(
    async (token) => {
      if (hasLoggedIn.current) return; // Prevent duplicate logins
      hasLoggedIn.current = true;

      console.log("Login...");

      try {
        const response = await fetch(constants.API_URL + "/auth", {
          credentials: "include",
          method: "POST",
          headers: {
            authorization: `Bearer ${token}`,
          },
        });

        console.log("Login done");

        await apolloClient.refetchQueries({
          include: ["user"],
          onQueryUpdated: () => {
            console.log("Refetch done");
            navigate("./");
            window.location.reload(true);
          },
        });
      } catch (e) {
        console.error("Login error:", e);
      }
    },
    [navigate]
  );

  useEffect(() => {
    if (!_.isEmpty(token)) {
      login(token);
    }
  }, [token, login]);

  return true;
}
