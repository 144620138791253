import React from "react";
import moment from "moment";
import { Tooltip } from "antd";

const TimePoint = ({ label, time, isRed }) => (
  <div className="time-point">
    <div className={`time ${isRed ? "red-time" : ""}`}>
      {time
        ? moment
            .utc(time)
            .format("HH:mm")
            .split("")
            .map((char, i) => <span key={i}>{char}</span>)
        : "-"}
    </div>
    <div className="tick"></div>
    <div className="label">{label}</div>
  </div>
);

const FlightTimeline = ({
  flight: {
    depIcao,
    arrIcao,
    eobt,
    tobt,
    tsat,
    ctot,
    atot,
    eetDuration,
    eta,
    depStand,
    arrStand,
  },
}) => {
  return (
    <div className="flight-timeline">
      <div className="timeline-container">
        <div className={`timeline timeline-${atot ? 8 : 0}`}>
          <div className="timeline-line"></div>

          <div className="airport-container">
            <div className="airport-code">{depIcao}</div>
            {depStand && (
              <Tooltip title="Parking stand" placement="bottom">
                <div className="airport-code-stand">{depStand || "-"}</div>
              </Tooltip>
            )}
          </div>

          <TimePoint label="EOBT" time={eobt} />
          <TimePoint label="TOBT" time={tobt} />
          <TimePoint label="TSAT" time={tsat} />
          <TimePoint label="CTOT" time={ctot} isRed={true} />
          {atot && <TimePoint label="ATOT" time={atot} />}

          <div className="eet-container">
            <div className="eet-label">
              EET: {moment.duration(eetDuration).asMinutes()}min
            </div>
          </div>
          <TimePoint label="ETA" time={eta} />

          <div className="airport-container">
            <div className="airport-code">{arrIcao}</div>
            {arrStand && (
              <Tooltip title="Parking stand" placement="bottom">
                <div className="airport-code-stand">{arrStand || "-"}</div>
              </Tooltip>
            )}
          </div>

          {/* 
          <div className="timeline-group center">
            <div className="points-group">
              
            </div>
          </div>

          
          <div className="timeline-group right">
            <Text className="airport-code">{arrIcao}</Text>
            <div className="points-group">
              <TimePoint label="ETA" time={eta} />
              <TimePoint label="ARR AIRPORT" time={null} />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default FlightTimeline;
