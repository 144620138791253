import * as React from "react";

import { useQuery, useReactiveVar } from "@apollo/client";

import { flightsSummariesGQL } from "../graphql/queries";

import { useDebounce } from "use-debounce";

import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

import { Dropdown, Menu, Checkbox, Input, Space } from "antd";
import _ from "lodash";
import {
  currentFlights,
  currentFlightsLoading,
  lastUpdate,
  liveUpdates,
} from "../graphql/reactiveVars.js";

export default function Search() {
  let navigate = useNavigate();
  let location = useLocation();

  const [search, onChangeSearch] = React.useState("");
  const [searchDebounced] = useDebounce(search, 600);
  const [width, changeWidth] = React.useState(250);
  const [selectedFields, setSelectedFields] = React.useState([]);

  const updatesEnabled = useReactiveVar(liveUpdates);

  let { loading, refetch } = useQuery(flightsSummariesGQL, {
    variables: { needle: searchDebounced, filter: selectedFields },
    fetchPolicy: "network-only",
    pollInterval: updatesEnabled ? 1000 * 60 * 3 : 0,
    notifyOnNetworkStatusChange: true, // needed to get the loading value updated during refetch
    skip: search.length < 3,
    onCompleted: (data) => {
      console.log(data);
      currentFlights((data && data.flightsSummaries) || []);
      lastUpdate(moment.utc().toDate());
      currentFlightsLoading(false);
      console.log("query completed");
    },
  });

  const onSearch = (event) => {
    console.log("search");
    refetch();
  };

  const handleInputChange = (event) => {
    onChangeSearch(event.target.value.toUpperCase());
  };

  const onFocus = () => {
    //changeWidth(300);
    if (location.pathname !== "/") navigate("/");
  };

  const fields = [
    { title: "Show airborne flights", value: "showAirborne" },
    { title: "Callsigns", value: "getByOperator" },
    { title: "Airports", value: "getByAirport" },
    { title: "Registrations", value: "getByRegistration" },
  ];

  return (
    <Input.Search
      suffix={
        <>
          <Filter
            fields={fields}
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
          />
        </>
      }
      placeholder="Search flight here..."
      value={search}
      onChange={handleInputChange}
      bordered={true}
      onFocus={onFocus}
      //onBlur={() => changeWidth(250)}
      onPressEnter={onSearch}
      onSearch={onSearch}
      loading={loading}
      style={{
        width,
        marginRight: 300 - width,
        marginLeft: 40,
        fontFamily: "mono",
      }}
    />
  );
}

function Filter({ setSelectedFields, selectedFields = [], fields = [] }) {
  const onChange = (checkedValues) => {
    console.log("checked", checkedValues);
    setSelectedFields(_.orderBy(checkedValues, "order"));
  };

  const hasFilterActive =
    selectedFields.length > 0 && selectedFields.length !== fields.length;

  const menu = (
    <Menu>
      <Menu.Item onClick={() => true} style={{ cursor: "normal" }}>
        Filter by
      </Menu.Item>
      {fields.map((ele, i) => (
        <Menu.Item key={i}>
          <Checkbox value={ele.value}>{ele.title}</Checkbox>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Checkbox.Group onChange={onChange} defaultValue={selectedFields}>
      <Dropdown overlay={menu}>
        {/* <FilterOutlined color={hasFilterActive ? "#AA0000" : "#00AA00"} /> */}
        <FontAwesomeIcon
          icon={faFilter}
          style={{
            fontSize: 14,
            color: hasFilterActive ? "red" : "white",
          }}
        />
      </Dropdown>
    </Checkbox.Group>
  );
}
