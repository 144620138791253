import * as React from "react";

import { Tag, Tooltip, Popover, Space } from "antd";
import {
  WarningOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";

import moment from "moment";
import _ from "lodash";
import { Airports } from "../../api/airports.js";

const dataDisplayColumns = [
  {
    title: "Callsign",
    titleText: "Callsign",
    dataIndex: "callsign",
    key: "callsign",
    order: 1,
    width: 100,
  },
  {
    title: "Reg #",
    dataIndex: "registration",
    key: "REG",
    order: 2,
  },
  {
    title: "DEP",
    //dataIndex: ["keys", "aerodromeOfDeparture"],
    key: "DEP",
    render: (text, record) => {
      const icao = _.get(record, "depIcao");
      const airport = _.find(Airports, { icao });
      const airportName = _.get(airport, "name");
      const country = _.get(airport, "country");
      return <Tooltip title={airportName + ", " + country}>{icao}</Tooltip>;
    },
    order: 3,
  },
  {
    title: "Stand",
    dataIndex: "depStand",
    key: "STAND",
    order: 4,
  },

  {
    title: "ARR",
    //dataIndex: ["keys", "aerodromeOfDestination"],
    key: "ARR",
    render: (text, record) => {
      const icao = _.get(record, "arrIcao");
      const airport = _.find(Airports, { icao });
      const airportName = _.get(airport, "name");
      const country = _.get(airport, "country");
      return <Tooltip title={airportName + ", " + country}>{icao}</Tooltip>;
    },
    order: 5,
  },
  {
    title: <Tooltip title="Estimated Off-Block Time">EOBT</Tooltip>,
    titleText: "EOBT",
    dataIndex: "eobt",
    render: (ele) => ele && moment.utc(ele).format("HH:mm"),
    key: "EOBT",
    order: 7,
  },
  {
    title: <Tooltip title="Target Off Block Time">TOBT</Tooltip>,
    titleText: "TOBT",
    dataIndex: "tobt",
    render: (ele) => ele && moment.utc(ele).format("HH:mm"),
    key: "TOBT",
    order: 8,
  },
  {
    title: <Tooltip title="Target Start-up Approval Time">TSAT</Tooltip>,
    titleText: "TSAT",
    dataIndex: "tsat",
    render: (ele) => ele && moment.utc(ele).format("HH:mm"),
    key: "TSAT",
    order: 9,
  },
  {
    title: <Tooltip title="Actual Take Off Time">ATOT</Tooltip>,
    titleText: "ATOT",
    dataIndex: "atot",
    render: (ele) => ele && moment.utc(ele).format("HH:mm"),
    key: "ATOT",
    initDisabled: true,
    order: 10,
  },
  {
    title: <Tooltip title="Flight plan status">FPL</Tooltip>,
    titleText: "FPL",
    render: (ele) => {
      let icon = (
        <Tooltip title={ele?.suspensionStatus}>
          <CheckCircleOutlined />
        </Tooltip>
      );
      if (ele?.suspensionStatus !== "NOT_SUSPENDED")
        icon = <WarningOutlined style={{ color: "red" }} />;
      return (
        <Space>
          {icon} {ele?.flightState}
        </Space>
      );
    },
    key: "FPL",
    order: 11,
    width: 200,
  },
  {
    title: <Tooltip title="Calculated Take Off Time">CTOT</Tooltip>,
    titleText: "CTOT",
    render: (ele) => {
      let hasCTOT = _.get(ele, "ctot");

      if (!hasCTOT) return false;
      else
        return (
          <>
            &nbsp;&nbsp;
            <Popover
              title={"CTOT details"}
              content={
                <>
                  Most Penalising Regulation:{" "}
                  {_.get(ele, "mostPenalisingRegulation")} <br />
                  IATA delay code:{" "}
                  {_.get(ele, "mostPenalisingRegulationCause.iataDelayCode")}
                </>
              }
            >
              <Tag color="red">
                {moment.utc(_.get(ele, "ctot")).format("HH:mm")}
              </Tag>
            </Popover>
          </>
        );
    },

    key: "CTOT",
    order: 12,
    width: 110,
  },
  {
    title: <Tooltip title="Planned Standard Initial Departure">SID</Tooltip>,
    titleText: "SID",
    dataIndex: ["depProc", "id"],
    key: "SID",
    order: 13,
    width: 100,
  },
];

export default dataDisplayColumns;
