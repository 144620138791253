import * as React from "react";
import { BrowserRouter } from "react-router-dom";

import { ConfigProvider, theme } from "antd";
import { GlobalHistory } from "./components/GlobalHistory";
import apolloClient from "./graphql/client";

import { Helmet } from "react-helmet";
import dataDisplayColumns from "./components/DataDisplay/dataDisplayColumns";
import { ApolloProvider } from "@apollo/client";
import { AppContext } from "./components/AppContext";
import AppAuth from "./AppAuth";
import _ from "lodash";

import "./styles/css/index.css";

export default function Dashboard() {
  const [currentColumns, setCurrentColumns] = React.useState(
    _.filter(dataDisplayColumns, (ele) => !ele.initDisabled)
  );
  const [currentFlights, setCurrentFlights] = React.useState([]);
  const [currentFlightsLoading, setCurrentFlightsLoading] =
    React.useState(false);
  const [liveUpdates, setLiveUpdates] = React.useState(false);
  const [lastUpdate, setLastUpdate] = React.useState(false);

  const [title, setTitle] = React.useState("CTOT APP Web");
  const [showAirborne, setShowAirborne] = React.useState(false);

  return (
    <BrowserRouter>
      <ConfigProvider
        theme={{
          algorithm: theme.darkAlgorithm,
          token: {
            // Seed Token
            //colorPrimary: "#2A247E",
            colorPrimary: "#4547B5",
            borderRadius: 2,

            // Alias Token
            colorBgContainer: "#262430",

            fontFamily:
              "'IBM Plex Sans', 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Droid Sans', 'Helvetica Neue', sans-serif",
          },
          components: {
            Drawer: {
              colorBgElevated: "#211E29",
            },
            Table: {
              colorBgContainer: "#211E29",
            },
            Input: {
              colorBgContainer: "#262430",
              borderRadius: 3,
              controlOutlineWidth: 0,
              lineWidth: 0,
            },
            Button: {
              lineWidth: 0,
              defaultShadow: "none",
              primaryShadow: "none",
            },
            Breadcrumb: {
              fontSize: 18,
            },
          },
        }}
      >
        <GlobalHistory />
        <AppContext.Provider
          value={{
            currentColumns,
            setCurrentColumns,
            currentFlights,
            setCurrentFlights,
            currentFlightsLoading,
            setCurrentFlightsLoading,
            liveUpdates,
            setLiveUpdates,
            lastUpdate,
            setLastUpdate,
            title,
            setTitle,
            showAirborne,
            setShowAirborne,
          }}
        >
          <ApolloProvider client={apolloClient}>
            <Helmet>
              <title>{title}</title>
            </Helmet>
            <AppAuth />
          </ApolloProvider>
        </AppContext.Provider>
      </ConfigProvider>
    </BrowserRouter>
  );
}
